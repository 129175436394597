const GetInTouch = () => {
  return (
    <div className="px-4 text-black">
      <div className="max-w-screen-lg mx-auto space-y-4 rounded bg-[#e6dddd] mt-24 p-6">
        <h2 className="text-4xl font-semibold">Get in Touch</h2>
        <p>
          If you have a question or want to get in touch with our team, you can
          reach us at: info@wizmeek.com.
        </p>
      </div>
    </div>
  );
};

export default GetInTouch;
