import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "./context/themeContext";
import store from "./redux/store";
import Router from "./router";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
