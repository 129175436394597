const TasteCard = (props: any) => {
  const { title, checked, onClick } = props;
  return (
    <div
      className={`relative cursor-pointer flex justify-center flex-col items-center bg-black w-full aspect-[1.1] md:w-[215px] md:h-[195px] rounded-full flex-shrink-0 ${
        checked ? "text-white" : "text-[#5A5A5A]"
      }`}
      onClick={onClick}
    >
      <div className="text-2xl md:text-xl">{title}</div>
      {checked && (
        <div className="absolute left-1/2 -translate-x-1/2 md:bottom-8">
          <svg
            width="33"
            height="28"
            viewBox="0 0 33 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8314 27.1047L0 16.2733L4.93605 11.3372L10.8314 17.25L28.064 0L33 4.93605L10.8314 27.1047Z"
              fill="#52FF00"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
export default TasteCard;
